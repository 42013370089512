// src/components/StudentList.js
import React, { useEffect, useState } from 'react';
import { getStudentsByBoardingHouseId } from '../services/apiService';
import AddStudent from './AddStudent'; // Import AddStudent as default
import '../styles/StudentList.css';
import UpdateStudent from './UpdateStudent';
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRectangleXmark } from '@fortawesome/free-solid-svg-icons'

const StudentList = ({ boardingHouseId }) => {
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);    
    const [searchTerm, setSearchTerm] = useState(''); // State to store search term

    const fetchStudents = async () => {
        try {
            const result = await getStudentsByBoardingHouseId(boardingHouseId);
            //console.log('API Result:', result); // Debugging: log the API result
            //const roomsData = result.data.$values; // Adjusting to your data structure

            //if (Array.isArray(roomsData)) {
                //console.log(roomsData)
                // Flattening the rooms array to extract students
                //const allStudents = roomsData.flatMap(room => room.$values);
                setStudents(result.data.$values);
            //} else {
            //    throw new Error('Unexpected data structure');
            ///}
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // Function to open the modal
    const openModalWithContent = (content) => {

        setModalContent(content);
        setModalOpen(true);
    };
    // Function to close the modal
    const closeModal = () => {
        setModalOpen(false);
        setModalContent(null);
    };

    useEffect(() => {
        if(boardingHouseId){
            fetchStudents();
        }
    }, [boardingHouseId]);

    const [filters, setFilters] = useState('');
    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setFilters({
            value
        });
    };

    const removeFilter = (filterKey) => {
        setFilters(
            ""
        );
        setSearchTerm("");
    };

    // Filter rooms by room name or client name
    const filteredStudents = students?.filter((student) => {
        const fullName = `${student?.fname} ${student?.lname}`.toLowerCase();
        const roomName = student?.roomName?.toLowerCase();
        return fullName.includes(searchTerm?.toLowerCase()) || roomName?.includes(searchTerm.toLowerCase());
    });
    
    const FilterPills = ({ filters, removeFilter }) => {
        const activeFilters = Object.entries(filters).filter(
            ([key, value]) => value && key !== 'boardingHouseId'
        );

        if (activeFilters.length === 0) return null;

        return (
            <div className="filter-pills">
                {activeFilters.map(([key, value]) => (
                    <span key={key} className="filter-pill">
                        {/* {key}: {value} */}
                        {/* <button className="clear-filter-results-btn" onClick={() => removeFilter(key)}>x</button> */}
                        <FontAwesomeIcon className="clear-filter-results-icon" onClick={() => removeFilter(key)} icon={faRectangleXmark} />
                    </span>
                ))}
            </div>
        );
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading students: {error.message}</div>;

    return (
        <div>
            {/* <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search room or client name..."
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <div className="search-input-filter">
                    <FilterPills filters={filters} removeFilter={removeFilter} />
                </div>
            </div> */}
            <div className="students-container">
                {/* <h3 className="students-title">Clients</h3> */}
                
            <div className="rooms-title title-and-add-room">
                <h3>Clients</h3>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search room or client name..."
                        value={searchTerm}
                        onChange={handleSearch}
                    />

                    <div className="search-input-filter">
                        <FilterPills filters={filters} removeFilter={removeFilter} />
                    </div>
                </div>
            </div>
                <table className="student-header-table">
                    <tr>
                        <td>#</td>
                        <td>Name</td>
                        <td>ID Number</td>
                        <td>Room Name/ID</td>
                        <td>Date of Entry</td>
                        <td>Warnings</td>
                    </tr>
                </table>

                {filteredStudents.length > 0 ? (
                    filteredStudents.map((student, index) => (
                        <div key={student.id} className="student-item">
                            <table className="student-table">
                                <tr>
                                    <td>{index + 1}</td>
                                    <td >
                                        <a onClick={() => { openModalWithContent(<UpdateStudent  studentId={student.id}  setError={setError}  fetchRooms={fetchStudents} />) }}>{student.fname}&nbsp;&nbsp;{student.lname}</a>
                                    </td>
                                    <td>{student.idNumber}</td>
                                    <td>{student.roomName}</td>
                                    <td>{new Date(student.dateOfEntry).toLocaleDateString()}</td>
                                    <td>{student.numberOfWarnings}</td>
                                </tr>
                            </table>

                        </div>
                    ))
                ) : (
                    <div>No students available.</div>
                )}
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    {modalContent}
                </Modal>
            </div>
        </div>
    );
};

export default StudentList;
