import React, { useState } from 'react';
import { login } from '../services/apiService'; // Import the login function from your axios service
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../styles/Login.css';
import { toast } from 'react-toastify';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();



    const handleLogin = async (e) => {
        e.preventDefault();
        const credentials = {
            username,
            password
        };
        try {
            const response = await login(credentials);
            localStorage.setItem('token', response.data.token); // Store the JWT
            localStorage.setItem('expirationTime', Date.now() + 72000000);
            navigate('/rooms'); // Redirect to the dashboard or home page after successful login
            window.location.reload(); // Reload the app
            toast.success(`Login successful`);
        } catch (err) {
            ///setError('Invalid username or password');
            toast.error(`Invalid username or password`);
        }
    };

    return (
        <div className="main-sign-in-container">
            <div className="sign-in-container">

                <div className="brand-box">
                    <h1 className="brand-title">SpaceKudu</h1>
                    <h2 className="brand-motto">Manage and track your properties <br />with ease.</h2>
                </div>
                <div className="right-side">
                    <h2 className="sign-in-title">Sign In</h2>
                    <form className="sign-in-box" onSubmit={handleLogin}>

                        <div className="sign-in-input-group">
                            {error && <p className="error-p">{error}</p>}
                            <input
                                type="text"
                                placeholder="Email"
                                value={username}
                                required
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                required
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button className="primary-btn sign-in-button" type="submit">Sign In</button>
                            <a>Forgot password?</a>
                            <hr className="sign-in-hr"></hr>
                            <p className="register-p"><Link to="/Register">Don't have an account?</Link></p>
                        </div>

                    </form>
                </div>

            </div>
            <div className="privacy-and-about">
                <Link to="/Privacy">Privacy</Link>
            </div>
            <div className="privacy-and-about">
                <a target="_blank" href="https://zerone-technologies-limited.github.io/index.html">&#169;ZerOne Technologies Limited | 2024</a>
            
            </div>
        </div>

    );
};

export default Login;
