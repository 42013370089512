import React, { useState } from 'react';
import { createStudentForRoom } from '../services/apiService';
import { toast  } from 'react-toastify';

const AddStudent = ({ roomId, boardinghouseId, fetchRooms, setError, closeModal }) => {
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [IDNumber, setIDNumber] = useState('');
    const [DOE, setDOE] = useState('');

    const handleAddStudent = async () => {
        if (!fname.trim() || !lname.trim() || !IDNumber.trim() || !DOE.trim()) {
            toast.error(`All fields are required`);
            return;
        }
        const student = {
            fname,
            lname,
            idNumber: IDNumber,
            dateOfEntry: DOE,
            boardinghouseId: boardinghouseId
        };
        try {
            await createStudentForRoom(roomId, student);
            //closeModal();
            notify();
            setFname('');
            setLname('');
            setIDNumber('');
            setDOE('');
            await fetchRooms(roomId); // Re-fetch the rooms to update the list with the new student
            
        } catch (error) {
            setError(error);
        }
    };

    const notify = () => {
        toast.success(`${fname} ${lname} added successfully!`);
    }

    return (
        <div className="add-student input-group">
            <div className='component-title'>Add Client</div>
            <div className="lable-and-input">
                <label>First Name </label>
                <input
                    type="text"
                    value={fname}
                    required
                    onChange={(e) => setFname(e.target.value)}
                    placeholder="First name"
                />
            </div>

            <div className="lable-and-input">
                <label>Last Name </label>
                <input
                    type="text"
                    value={lname}
                    required
                    onChange={(e) => setLname(e.target.value)}
                    placeholder="Last name"
                />
            </div>

            <div className="lable-and-input">
                <label>ID Number</label>
                <input
                    type="text"
                    value={IDNumber}
                    onChange={(e) => setIDNumber(e.target.value)}
                    placeholder="ID Number"
                />
            </div>

            <div className="lable-and-input">
                <label>Date </label>
                <input
                    type="date"
                    value={DOE}
                    required
                    onChange={(e) => setDOE(e.target.value)}
                    placeholder="Date Of Entry"
                />
            </div>

            <button className="primary-btn" onClick={handleAddStudent}>Add Client</button>

            {/*<button className="primary-btn" onClick={notify}>notify</button>*/}
        </div>
    );
};

export default AddStudent;
