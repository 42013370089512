import React, { useState, useEffect } from 'react';
import { getStudentDetails, UpdateStudentDetails, createRentForStudent } from '../services/apiService';
import { toast  } from 'react-toastify';


const UpdateStudent = ({ rooms, studentId, fetchRooms, setError }) => {
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [IDNumber, setIDNumber] = useState('');
    const [DOE, setDOE] = useState('');
    const [studentDetails, setStudentDetails] = useState({});
    const [room, setRoom] = useState([]);
    const [isEditable, setIsEditable] = useState(false);
    const [isEdited, setIsEdited] = useState(false); 
    const [selectedRoom, setSelectedRoom] = useState(null); 

    useEffect(() => {
        const fetchStudentDetails = async () => {
            const result = await getStudentDetails(studentId);
            setStudentDetails(result.data);
            //console.log(result);
        };
        fetchStudentDetails();
    }, [studentId]);

    useEffect(() => {
        if (studentDetails) {
            setFname(studentDetails.fname || '');
            setLname(studentDetails.lname || '');
            setIDNumber(studentDetails.idNumber || '');
            setDOE(studentDetails.dateOfEntry ? studentDetails.dateOfEntry.split('T')[0] : '');
            setRoom(studentDetails.room);

            if (studentDetails.dateOfEntry) {
                setIsEditable(!hasBeenMoreThanOneMonth(studentDetails.dateOfEntry));
            }
        }
    }, [studentDetails]);

    const hasBeenMoreThanOneMonth = (dateOfEntry) => {
        const entryDate = new Date(dateOfEntry);
        const currentDate = new Date();
        const yearDifference = currentDate.getFullYear() - entryDate.getFullYear();
        const monthDifference = currentDate.getMonth() - entryDate.getMonth();
        const totalMonthsDifference = yearDifference * 12 + monthDifference;

        if (totalMonthsDifference > 1) {
            return true;
        } else if (totalMonthsDifference === 1) {
            return currentDate.getDate() >= entryDate.getDate();
        } else {
            return false;
        }
    };

    const checkIfEdited = () => {
        if (
            fname !== studentDetails.fname ||
            lname !== studentDetails.lname ||
            IDNumber !== studentDetails.idNumber
        ) {
            setIsEdited(true);
        } else {
            setIsEdited(false);
        }
    };

    useEffect(() => {
        checkIfEdited();
    }, [fname, lname, IDNumber]);

    const handleUpdateStudent = async () => {
        if (!fname.trim() || !lname.trim() || !IDNumber.trim() || !DOE.trim()) {
            //alert('All fields are required');
            toast.error(`All fields are required`);
            return;
        }

        if (hasBeenMoreThanOneMonth(DOE)) {
            //alert('This student has been in their room for more than one month.');
            toast.error(`This client has been in their room for more than one month.`);
            return;
        }

        const student = {
            fname,
            lname,
            idNumber: IDNumber,
            roomId: (selectedRoom !== null) ? selectedRoom.id : room.id
        };  

        if (selectedRoom) {
            // Get today's date
            const today = new Date();
        
            const rent = {
                StudentId:  studentId,
                Amount: null, // Set the rent amount to null
                StartDate: today.toISOString(), // Set today's date as StartDate
                EndDate: today.toISOString() // Optionally, use the same date for EndDate, or adjust as needed
            };
        
            try {
                // Record the rent with null amount and today's date
                await createRentForStudent(studentId, rent);
            } catch (error) {
                setError(error); // Handle any errors
                toast.error(error);
            }
        }        

        try {
            await UpdateStudentDetails(studentId, student);
            toast.success(`${fname} ${lname} succesfully updated!`);
            setFname('');
            setLname('');
            setIDNumber('');
            setDOE('');
            await fetchRooms(); 
            setIsEdited(false);
        } catch (error) {
            setError(error);
            toast.error(`Failed to Update ${fname} ${lname}`);
        }
    };

    // Function to handle deleting the student from the room (setting roomId to null)
    const handleDeleteFromRoom = async () => {
        // Show confirmation dialog
        const confirmDelete = window.confirm('Are you sure you want to delete this client from room? ');
        if (!confirmDelete) return; // Exit if user cancels

        const student = {
            fname,
            lname,
            idNumber: IDNumber,
            roomId: null // Set roomId to null to remove the student from the room
        };

        try {
            await UpdateStudentDetails(studentId, student);
            toast.success(`${fname} ${lname} Succesfully deleted from room!`);
            await fetchRooms(); // Refresh the room list
            setSelectedRoom(null); // Clear selected room state
        } catch (error) {
            setError(error);
        }
    };

    const handleRoomChange = (e) => {
        try {
            const roomId = e.target.value;
            const room = rooms.find(r => r.id === parseInt(roomId));
            setSelectedRoom(room);
        } catch (error) {
            //console.error("Error handling room change:", error);
        }
    };

    return (
        <div className="update-student input-group">
            <div className='component-title'>{room?.name}</div>
            <div className='component-title'>Update Client</div>
            <div className="lable-and-input">
                <label>First Name </label>
                <input
                    type="text"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    placeholder="First name"
                    disabled={!isEditable}
                />
            </div>

            <div className="lable-and-input">
                <label>Last Name</label>
                <input
                    type="text"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                    placeholder="Last name"
                    disabled={!isEditable}
                />
            </div>

            <div className="lable-and-input">
                <label>ID Number</label>
                <input
                    type="text"
                    value={IDNumber}
                    onChange={(e) => setIDNumber(e.target.value)}
                    placeholder="ID Number"
                    disabled={!isEditable}
                />
            </div>

            <div className="lable-and-input">
                <label>Date of Entry</label>
                <input
                    type="date"
                    value={DOE}
                    onChange={(e) => setDOE(e.target.value)}
                    placeholder="Date Of Entry"
                    disabled
                />
            </div>

            {/* {(!isEditable) && (
                <label>Oops, cannot edit client, try removing from room.</label>
            )} */}

            {(rooms?.length > 0) &&
                (
                    <div>
                        {/* <div>Room Actions</div> */}
                        <div className='room-actions'>
                            <label>Change Room:</label>
                            <select value={selectedRoom?.id || ''} onChange={handleRoomChange} >
                                <option value="" disabled>Select a room</option>
                                {rooms?.map((room) => (
                                    (room.id !== studentDetails.roomId) ? 
                                        <option key={room.id} value={room.id}>
                                            {room.name}
                                        </option>
                                        :
                                        <option key={room.id} value={room.id} disabled>
                                            {room.name} (current room)
                                        </option>
                                ))}
                            </select>
                            {selectedRoom && <p>Click Update to move {fname} {lname} from {room.name} to {selectedRoom.name}</p>}
                            
                            
                        </div>
                    </div>
                )}
                <button className="primary-btn" onClick={handleUpdateStudent} disabled={!isEditable}>Update</button>
            {(room) && (<button className="primary-btn delete-btn" onClick={handleDeleteFromRoom}>Delete</button>)}
            
        </div>
    );
};

export default UpdateStudent;
