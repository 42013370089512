import { getRoomsByBoardingHouseId } from '../services/apiService';
import React, { useEffect, useState } from 'react';
import '../styles/VacancyReport.css';


const VacancyReport = ({ boardingHouseId }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [rooms, setRooms] = useState([]);

    const fetchRooms = async () => {
        try {
            const result = await getRoomsByBoardingHouseId(boardingHouseId);
            //console.log('API Result:', result); // Debugging: log the API result
            const roomsData = result.data.$values; // Adjusting to your data structure

            if (Array.isArray(roomsData)) {
                // Flattening the rooms array to include references
                const flattenedRooms = flattenRooms(roomsData);
                setRooms(flattenedRooms);
            } else {
                throw new Error('Unexpected data structure');
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // Function to flatten rooms data structure
    const flattenRooms = (roomsArray) => {
        const roomsMap = {};

        const addRoomToMap = (room) => {
            if (!roomsMap[room.id]) {
                roomsMap[room.id] = room;
            }
        };

        roomsArray.forEach(room => {
            if (room.$ref) {
                // If it's a reference, it should already be in the map
                return;
            }
            addRoomToMap(room);

            // If the room has nested rooms, add them as well
            if (room.boardingHouse?.rooms?.$values) {
                room.boardingHouse.rooms.$values.forEach(nestedRoom => {
                    if (nestedRoom.$ref) {
                        // Handle references if needed
                        const refId = nestedRoom.$ref.split("/")[1];
                        const referencedRoom = roomsArray.find(r => r.$id === refId);
                        addRoomToMap(referencedRoom);
                    } else {
                        addRoomToMap(nestedRoom);
                    }
                });
            }
        });

        return Object.values(roomsMap);
    }

    // Utility function to determine status color
    const getStatusColor = (numOfSpaces, availability) => {

        if (!numOfSpaces) {

            return 'vacancy-yellow'; //room is under maintenence
        }
        else if (numOfSpaces && availability === 0) {
            return 'vacancy-red'; // room is fully occupied
        } else {
            return 'vacancy-green'; // room has free spaces
        }
    };

    useEffect(() => {
        fetchRooms();
    }, [boardingHouseId]);

    return (
        <div className="vacancy-container">
            <div className="vacancy-title">
                <h3>Vacancies</h3>
            </div>

            <table className="vacancy-header-table">
                <tr>
                    <td className="room-name-col">Room</td>
                    <td className="gender-col">Gender</td>
                    <td className="no-of-spaces-col">No Of Spaces</td>
                    <td className="no-of-clients-col">No of Clients</td>
                    <td className="available-spaces-col">Available Spaces</td>
                    <td className="clients-col">Clients</td>
                </tr>
            </table>
            <table className=" vacancy-table">
                {rooms.length > 0 ? (
                    rooms.map((room, index) => (

                        <div key={room.id} className="table-rows">

                            <tr>

                                <td className="room-name-col">
                                    <div >
                                        <a>{room.name} </a>
                                    </div>
                                </td>
                                <td className="gender-col">
                                    <p>{room.gender ? 'Female' : 'Male'}</p>
                                </td>
                                <td className={`no-of-spaces-col ${getStatusColor(room.capacity, room.capacity - room.students.$values.length)}`}>
                                    <p > {room.capacity}</p>
                                </td>
                                {/* <td className="no-of-clients-col">
                                    <p > {room.students.$values.length}</p>
                                </td> */}
                                <td className={`no-of-clients-col ${getStatusColor(room.capacity, room.capacity - room.students.$values.length)}`}>
                                    <p > {room.students.$values.length}</p>
                                </td>
                                <td className={`available-spaces-col ${getStatusColor(room.capacity, room.capacity - room.students.$values.length)}`}>
                                    <p> {room.capacity - room.students.$values.length}</p>
                                </td>

                                <td className="clients-col">
                                    {room.students?.$values?.map(student => (
                                        <div className="clients" key={student.id}>
                                            <div>
                                                <a className="no-break">{student.fname} {student.lname}</a>
                                                {/* <a >&nbsp;{student.lname}</a> */}
                                            </div>
                                        </div>
                                    ))}
                                </td>
                            </tr>


                        </div>

                    ))
                ) : (
                    <div>No rooms available</div>
                )}
            </table>

        </div>
    );
};

export default VacancyReport;