import { getRoomsByBoardingHouseId } from '../services/apiService';
import React, { useEffect, useState } from 'react';
import '../styles/VacancyReport.css';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AccountsReport = ({ boardingHouseId }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [rooms, setRooms] = useState([]);

    const fetchRooms = async () => {
        try {
            const result = await getRoomsByBoardingHouseId(boardingHouseId);
            //console.log('API Result:', result); // Debugging: log the API result
            const roomsData = result.data.$values; // Adjusting to your data structure

            if (Array.isArray(roomsData)) {
                // Flattening the rooms array to include references
                const flattenedRooms = flattenRooms(roomsData);
                setRooms(flattenedRooms);
            } else {
                throw new Error('Unexpected data structure');
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // Function to flatten rooms data structure
    const flattenRooms = (roomsArray) => {
        const roomsMap = {};

        const addRoomToMap = (room) => {
            if (!roomsMap[room.id]) {
                roomsMap[room.id] = room;
            }
        };

        roomsArray.forEach(room => {
            if (room.$ref) {
                // If it's a reference, it should already be in the map
                return;
            }
            addRoomToMap(room);

            // If the room has nested rooms, add them as well
            if (room.boardingHouse?.rooms?.$values) {
                room.boardingHouse.rooms.$values.forEach(nestedRoom => {
                    if (nestedRoom.$ref) {
                        // Handle references if needed
                        const refId = nestedRoom.$ref.split("/")[1];
                        const referencedRoom = roomsArray.find(r => r.$id === refId);
                        addRoomToMap(referencedRoom);
                    } else {
                        addRoomToMap(nestedRoom);
                    }
                });
            }
        });

        return Object.values(roomsMap);
    }

    useEffect(() => {
        fetchRooms();
    }, [boardingHouseId]);


    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Expected Rent Over Time',
                data: [],
                backgroundColor: 'rgba(91,153,194,0.5)',
            },
        ],
    });
    
    useEffect(() => {
        if (rooms.length > 0) {
            const lastRentEndDates = [];
    
            rooms.forEach((room) => {
                room.students.$values.forEach((student) => {
                    let rents = student.rents.$values;
                    if (rents.length > 0) {
                        let lastRent = rents[rents.length - 1];
                        lastRentEndDates.push({
                            date: new Date(lastRent.endDate),
                            name: `${student.fname} ${student.lname}`,
                            room: room.name,
                            amount: room.cost,
                        });
                    }
                });
            });
    
            // Group amounts by month and year
            const monthlyData = {};
            lastRentEndDates.forEach(({ date, amount }) => {
                const monthYear = `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`; // Month + Year
                if (!monthlyData[monthYear]) {
                    monthlyData[monthYear] = 0;
                }
                monthlyData[monthYear] += amount;
            });

            // Prepare data for the chart
            const labels = Object.keys(monthlyData);
            const data = Object.values(monthlyData);

            setChartData({
                labels,
                datasets: [
                    {
                        label: 'Expected Rent Over Time',
                        data,
                        backgroundColor: 'rgba(91,153,194,0.5)',
                    },
                ],
            });
        }
    }, [rooms]);
    

    return (
        <div className="accounts-report">
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error.message}</p>
            ) : (
                <div>
                    <h2>Expected Rent Over the Months</h2>
                    <Bar
                        data={chartData}
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                },
                                title: {
                                    display: true,
                                    text: 'Expected Rent per Month',
                                },
                            },
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: 'Month',
                                    },
                                },
                                y: {
                                    title: {
                                        display: true,
                                        text: 'Amount (ZMW)',
                                    },
                                    beginAtZero: true,
                                },
                            },
                        }}
                    />
                </div>
            )}
        </div>
    );
    
};

export default AccountsReport;