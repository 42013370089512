import React, { useState, useEffect } from 'react';
import { getAllCosts } from '../services/apiService';
import AddCost from './AddCost';
import Modal from './Modal';
import '../styles/Accounts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import the FontAwesomeIcon component
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
const COST_TYPE_OPTIONS = ["Income", "Expense"];
const COST_CATEGORY_OPTIONS = [
  "Rent",
  "Salaries",
  "CleaningMaterials",
  "Utilities",
  "Maintenance",
  "FoodSupplies",
  "CollateralPayment",
  "OppositeEntry",
  "Other"
];

const FilterPills = ({ filters, removeFilter }) => {
  const activeFilters = Object.entries(filters).filter(
    ([key, value]) => value && key !== 'boardingHouseId'
  );

  if (activeFilters.length === 0) return null;

  return (
    <div className="account-filter-pills">
      {activeFilters.map(([key, value]) => (
        <div key={key} className="filter-pill-secondary">
          <div className="filter-key-value">
            {key}: {value}
          </div>
          <div className="remove-filter" onClick={() => removeFilter(key)}>
            <FontAwesomeIcon className="clear-filter-icon" onClick={() => removeFilter(key)} icon={faXmark} />
          </div>
          {/* <FontAwesomeIcon className="small-clear-filter-results-icon" onClick={() => removeFilter(key)} icon={faXmark} /> */}

        </div>
      ))}
    </div>
  );
};

const AccountList = ({ boardingHouseId }) => {
  const [costs, setCosts] = useState([]);
  const [filters, setFilters] = useState({
    boardingHouseId: '',
    type: '',
    category: '',
    fromDate: '',
    toDate: '',
    minAmount: '',
    maxAmount: '',
    noteToken: ''
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModalWithContent = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  const fetchCosts = async () => {
    try {
      const response = await getAllCosts(
        boardingHouseId,
        filters.type || null,
        filters.category || null,
        null,
        filters.minAmount || null,
        filters.maxAmount || null,
        filters.noteToken || null,
      );

      const filteredCosts = response.data.$values.filter(cost => {
        const costDate = new Date(cost.date);
        const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
        const toDate = filters.toDate ? new Date(filters.toDate) : null;

        return (
          (!fromDate || costDate >= fromDate) &&
          (!toDate || costDate <= toDate)
        );
      });

      setCosts(filteredCosts);
    } catch (error) {
      //console.error('Error fetching costs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCosts();
  }, [filters, boardingHouseId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  // check if any filter has non-empty value
  const isAnyFilterSet = Object.values(filters).some((filterValue) => filterValue);
 
  const removeFilter = (filterKey) => {
    setFilters({
      ...filters,
      [filterKey]: ''
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading rooms: {error.message}</div>;

  return (
    <div className="accounts-container">
      <div className="title-and-new-entry">
        <h3 className="accounts-title">Accounts</h3>
        <button className="primary-btn" onClick={() => openModalWithContent(<AddCost boardingHouseId={boardingHouseId} fetchRooms={fetchCosts} setError={setError} />)}>Add Cost</button>
      </div>

      <div className="filter-bar">
        <h3>Filters</h3>
        <div className="filters">
          <select name="type" value={filters.type} onChange={handleInputChange}>
            <option value="">Select Type</option>
            {COST_TYPE_OPTIONS.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>

          <select name="category" value={filters.category} onChange={handleInputChange}>
            <option value="">Select Category</option>
            {COST_CATEGORY_OPTIONS.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>

          <input
            type="date"
            name="fromDate"
            placeholder="From Date"
            value={filters.fromDate}
            onChange={handleInputChange}
          />
          <div className="duration">To</div>
          <input
            type="date"
            name="toDate"
            placeholder="To Date"
            value={filters.toDate}
            onChange={handleInputChange}
          />
          <input
            type="number"
            name="minAmount"
            placeholder="Min Amount"
            value={filters.minAmount}
            onChange={handleInputChange}
          />
          <input
            type="number"
            name="maxAmount"
            placeholder="Max Amount"
            value={filters.maxAmount}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="noteToken"
            maxlength="40"
            placeholder="Search Description"
            value={filters.noteToken}
            onChange={handleInputChange}
          />
        </div>
        <div className="applied-filter-pills">
          <FilterPills filters={filters} removeFilter={removeFilter} />
        </div>
        {/* Render Filter Pills */}

      </div>

      <table className={`accounts-header-table ${isAnyFilterSet ? 'filter-triggered' : ''}`}>
        <tr>
          <td className="id-col">ID</td>
          <td className="amount-col">Amount</td>
          <td className="description-col">Description</td>
          <td className="date-col">Date</td>
          <td className="cost-type-col">Type</td>
          <td className="category-col">Category</td>
          <td className="property-col">Property Name</td>
        </tr>
      </table>
      <div className="item">
        {costs.map((cost, index) => (
          <table className="accounts-table">
            <tr key={cost.id}>
              <td className="id-col">{index}</td>
              <td className="amount-col make-bold">{cost.amount}</td>
              <td className="description-col">{cost.description}</td>
              <td className="date-col">{new Date(cost.date).toLocaleDateString()}</td>
              <td className="cost-type-col">{COST_TYPE_OPTIONS[cost.type]}</td>
              <td className="category-col">{COST_CATEGORY_OPTIONS[cost.category]}</td>
              <td className="property-col">House, Room</td>
            </tr>
          </table>
        ))}
      </div>

      {/* Display total sum */}
      <div className="total-amount">
        <h4>Total Amount: {costs.reduce((sum, cost) => sum + cost.amount, 0)}</h4>
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {modalContent}
      </Modal>
    </div>
  );
};


export default AccountList;
